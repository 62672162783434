(function() {
	'use strict';

	var
		detectedPlatform = avastGlobals.detect.os.name.toLowerCase() === 'windows' ? 'pc' : avastGlobals.detect.os.name.toLowerCase(),
		setActivePanel = function(platform){
			var
				togglers = document.querySelectorAll('.section-tabs .toggler-item'),
				activeToggler = document.querySelector('[aria-controls="content-'+platform+'"]'),
				panels = document.querySelectorAll('.section-tabs .tab-panel'),
				activePanel = document.getElementById('content-'+platform);

			// reset togglers
			togglers.forEach(function(toggler){
				toggler.classList.remove("active");
				toggler.setAttribute('aria-selected', 'false');
			});

			// reset panels
			panels.forEach(function(panel){
				panel.classList.remove("show", "active");
			});

			// set toggler and panel active by detected platform
			activeToggler.classList.add("active");
			activeToggler.setAttribute('aria-selected', 'true');
			activePanel.classList.add("show", "active");
		};
	
	// Set active panel on comparison table
	setActivePanel(detectedPlatform);
})();


//
//	Carousels
//
var infFeaturesPc = tns({
	"container": ".slider-infFeatures-pc",
	"controlsContainer": ".slider-infFeatures-pc-controls",
	"preventScrollOnTouch": "force",
	"controlsPosition": "bottom",
	"navPosition": "bottom",
	"gutter": 24,
	"slideBy": "page",
	"responsive": {
		"768": {
			"items": 2
		},
		"992": {
			"items": 1,
			"gutter": 32
		},
		"1280": {
			"items": 2
		}
	}
});
// Create a slide counter bellow the Carousel
avm.require('avast.web.slideCounter')(infFeaturesPc);

var infFeaturesMac = tns({
	"container": ".slider-infFeatures-mac",
	"controlsContainer": ".slider-infFeatures-mac-controls",
	"preventScrollOnTouch": "force",
	"controlsPosition": "bottom",
	"navPosition": "bottom",
	"gutter": 24,
	"slideBy": "page",
	"responsive": {
		"768": {
			"items": 2
		},
		"992": {
			"items": 1,
			"gutter": 32
		},
		"1280": {
			"items": 2
		}
	}
});
// Create a slide counter bellow the Carousel
avm.require('avast.web.slideCounter')(infFeaturesMac);

var infFeaturesAndroid = tns({
	"container": ".slider-infFeatures-android",
	"controlsContainer": ".slider-infFeatures-android-controls",
	"preventScrollOnTouch": "force",
	"controlsPosition": "bottom",
	"navPosition": "bottom",
	"gutter": 24,
	"slideBy": "page",
	"responsive": {
		"768": {
			"items": 2
		},
		"992": {
			"items": 1,
			"gutter": 32
		},
		"1280": {
			"items": 2
		}
	}
});
// Create a slide counter bellow the Carousel
avm.require('avast.web.slideCounter')(infFeaturesAndroid);
